import { getParams } from "./getParams";
import Filters from "./Filters";

import normalizeString from "./normalizeString";


export default class FiltersBuilder {

  constructor() {
    this.offset = 0;
    this.pageLimit = 20;

    // Esta funcion incluye los datos necesarios a traer para mostrar en los teasers
    this.params = getParams('node--inmueble', 'facet')
      .addPageOffset(this.offset)
      .addPageLimit(this.pageLimit);
  }

  addFilter(filterKey, value) {
    if(!Filters.filters.hasOwnProperty(filterKey)) throw new Error(`El filtro aplicado (${filterKey}) no existe en la lista de los filtros iniciales.`);
    // value is an object like { value: "", label: "" }
    if(Array.isArray(Filters.filters[filterKey].values)) {
      Filters.filters[filterKey].values.push(value);
    } else {
      Filters.filters[filterKey].values = value;
    }
    return this;
  }

  setFilter(filterKey, value) {
    if(!Filters.filters.hasOwnProperty(filterKey)) throw new Error(`El filtro aplicado (${filterKey}) no existe en la lista de los filtros iniciales.`);
    if(Array.isArray(value)) {
      Filters.filters[filterKey].values = value;
    } else {
      Filters.filters[filterKey].values = [value];
    }
    return this;
  }


  resetFilter(filterKey) {
    if(!Filters.filters.hasOwnProperty(filterKey)) throw new Error(`El filtro aplicado (${filterKey}) no existe en la lista de los filtros iniciales.`);
    Filters.filters[filterKey].values = [];
    return this;
  }

  setOffset(offset) {
    this.offset = offset;
    this.params.addPageOffset(offset)
  }

  async queryResolver(queryString) {
    // Catalogos
    const propertyTypeJson = await import("../json/property_type.json");
    const treatmentTypeJson = await import("../json/treatment_type.json");
    const stateJson = await import("../json/estados.json");
    const municipalityJson = await import("../json/municipios.json");
    const localidadJson = await import("../json/localidades.json");
    const exterioresJson = await import("../json/caracteristicas_exteriores.json");
    const generalesJson = await import("../json/caracteristicas_generales.json");
    const recreacionJson = await import("../json/caracteristicas_recreacion.json");
    const politicasJson = await import("../json/caracteristicas_politicas.json");


    const tokensParkingspace = [
      {nombre: "1-estacionamiento", clave: "1"},
      {nombre: "2-estacionamientos", clave: "2"},
      {nombre: "3-estacionamientos", clave: "3"},
      {nombre: "4-estacionamientos", clave: "4"},
      {nombre: "5-estacionamientos", clave: "5"}
    ]

    const tokensRestrooms = [
      {nombre: "1-baño", clave: "1"},
      {nombre: "2-baños", clave: "2"},
      {nombre: "3-baños", clave: "3"},
      {nombre: "4-baños", clave: "4"},
      {nombre: "5-baños", clave: "5"}
    ]

    const tokensBedrooms = [
      {nombre: "1-recamara", clave: "1"},
      {nombre: "2-recamaras", clave: "2"},
      {nombre: "3-recamaras", clave: "3"},
      {nombre: "4-recamaras", clave: "4"},
      {nombre: "5-recamaras", clave: "5"}
    ]

    const tokensCurrencyIso = [
      {nombre: "mxn", clave: "MXN"},
      {nombre: "usd", clave: "USD"},
    ]

    const catalogs = {
      field_property_type_id: propertyTypeJson.default,
      field_treatement_type_id: treatmentTypeJson.default,
      field_currency_iso: tokensCurrencyIso,
      field_state: stateJson.default,
      field_municipality: municipalityJson.default,
      field_localidad: localidadJson.default,
      field_exterior: exterioresJson.default,
      field_general: generalesJson.default,
      field_recreacion: recreacionJson.default,
      field_politicas: politicasJson.default,
      field_restrooms: tokensRestrooms,
      field_bedrooms: tokensBedrooms,
      field_parkingspaces: tokensParkingspace,
      field_price: [],
      page: [],
      field_direccion: [],
    }


    // Orden: localidades, municipios, estados, exterior, general, recreacion, politicas, ... lo restante (estacionamientos, recamaras, etc)
    queryString = normalizeString(queryString.replace(/--+/g, "-").split(" ").join("-"));
    const filterKeys = Object.keys(Filters.filters);

    filterKeys.forEach(filterKey => {
      let {name, values, conjunction, operator, prefix} = Filters.filters[filterKey];
      let catalog = catalogs[name];

      let filterValues = [];
      let customConjunction = undefined;
      let alias = "";
      switch (name) {
        case "field_localidad":
          catalog.forEach(token => {

            const localityName = token.originalLabel;
            const municipalityName = token.municipio.label;
            const stateName = token.estado.label;

            alias = normalizeString(`en-${localityName}-municipio-${municipalityName}-${stateName === "Estado de México" ? "": "estado-"}${stateName}`.split(" ").join("-"));

            if(queryString.includes(alias)) {
              // console.log("loc: ", alias, name);
              token.alias = alias;
              filterValues.push(token);
              queryString = queryString.replace(alias, "");
            }
          });
          customConjunction = `ubicacion`;
          this.params.addGroup(customConjunction, conjunction);
          filterValues.map(filterValue => {
            this.params.addFilter(name, filterValue.value, operator, customConjunction);
            this.addFilter(filterKey, {value: filterValue.value, label: filterValue.label, alias: filterValue.alias});
          });
          break;
        case "field_municipality":
          catalog.forEach(token => {

            const municipalityName = token.originalLabel;
            const stateName = token.estado.label;

            alias = normalizeString(`en-${municipalityName}-${stateName === "Estado de México" ? "" : "estado-"}${stateName}`.split(" ").join("-"));

            if(queryString.includes(alias)) {
              // console.log("Mun: ", alias, name);
              token.alias = alias;
              filterValues.push(token);
              queryString = queryString.replace(alias, "");
            }
          });
          customConjunction = `ubicacion`;
          this.params.addGroup(customConjunction, conjunction);
          filterValues.map(filterValue => {
            this.params.addFilter(name, filterValue.value, operator, customConjunction);
            this.addFilter(filterKey, {value: filterValue.value, label: filterValue.label, alias: filterValue.alias});
          });
          break;
        case "field_state":
          catalog.forEach(token => {

            const stateName = token.originalLabel;

            alias = normalizeString(`en-${stateName}`.split(" ").join("-"));

            if(queryString.includes(alias)) {
              // console.log("St: ", alias, name);
              token.alias = alias;
              filterValues.push(token);
              queryString = queryString.replace(alias, "");
            }
          });
          customConjunction = `ubicacion`;
          this.params.addGroup(customConjunction, conjunction);
          filterValues.map(filterValue => {
            this.params.addFilter(name, filterValue.value, operator, customConjunction);
            this.addFilter(filterKey, {value: filterValue.value, label: filterValue.originalLabel, alias: filterValue.alias});
          });
          break;
        case "field_exterior":
        case "field_general":
        case "field_recreacion":
        case "field_politicas":
          catalog.forEach(token => {
            let originalLabel = normalizeString(token.nombre.split(" ").join("-"));
            alias = `con-${originalLabel}`;
            if(queryString.includes(alias)) {
              console.log(`Field: `, alias, name)
              filterValues.push(token);
              queryString = queryString.replace(`${alias}`, '');
            }
          });
          filterValues.map(filterValue => {
            this.params.addFilter(name, filterValue.clave, operator);
            this.addFilter(filterKey, {value: filterValue.clave, label: filterValue.nombre, alias: alias});
          });
          break;
        case "field_property_type_id":
        case "field_treatement_type_id":
        case "field_currency_iso":
          catalog.forEach(token => {
            let originalLabel = normalizeString(token.nombre.split(" ").join("-"));
            alias = `${originalLabel}`;
            if(queryString.includes(alias)) {
              filterValues.push(token);
              queryString = queryString.replace(`${alias}`, '');
            }
          });
          filterValues.map(filterValue => {
            this.params.addFilter(name, filterValue.clave, operator);
            this.addFilter(filterKey, {value: filterValue.clave, label: filterValue.nombre, alias: alias});
          });
          break;
        case "field_bedrooms":
        case "field_restrooms":
        case "field_parkingspaces":
          catalog.forEach(token => {
            const originalLabel = normalizeString(token.nombre.split(" ").join("-"));
            alias = `${prefix}${originalLabel}`;
            if(queryString.includes(alias)) {
              // console.log(`BRP: `, originalLabel, name);
              filterValues.push(token);
              queryString = queryString.replace(`${alias}`, '');
            }
          });
          filterValues.map(filterValue => {
            customConjunction = `${name}-${conjunction}`;
            this.params.addGroup(customConjunction, conjunction);
            this.params.addFilter(name, filterValue.clave, operator, customConjunction);
            this.addFilter(filterKey, {value: filterValue.clave, label: filterValue.nombre, alias: alias});
          });
          break;
        case "field_price":
          const indexPrefix = queryString.indexOf(prefix) + prefix.length;
          let valueArray = queryString.substring(indexPrefix).split('-')
          let valueString = valueArray[0];
          let valueNumber = parseInt(valueString);
          alias = `${prefix}${valueNumber}`;
          if (!isNaN(valueNumber)) {
            // console.log(valueNumber, name);
            queryString = queryString.replace(`${alias}`, ''); //Se remueve la caracteristica que ya fue agregada.
            this.params.addFilter(name, valueString, operator);
            this.addFilter(filterKey, {value: valueNumber, label: valueNumber, alias: alias});
          }
          break;
        case "page":
          if(queryString.includes(prefix)) {
            const indexPrefix = queryString.indexOf(prefix) + prefix.length;
            let valueArray = queryString.substring(indexPrefix).split('-');
            let valueString = valueArray[0];
            let valueNumber = parseInt(valueString);
            alias = `${prefix}${valueNumber}`;
            if (!isNaN(valueNumber)) {
              // console.log(valueNumber, name);
              queryString = queryString.replace(`${alias}`, ''); //Se remueve la caracteristica que ya fue agregada.
              const newOffset = (valueNumber - 1) * this.pageLimit;
              this.setOffset(newOffset);
              // this.params.addFilter(name, valueString, operator);
              this.addFilter(filterKey, {value: valueNumber, label: valueNumber, alias: alias});
            }
          }
          break;
        default:

          break;
      }
    });

    // console.log(queryString);
    // console.log(Filters.filters);
    return this.params;
  }

  static buildAlias (key, values) {
    switch (key) {
      case "field_state":
        return normalizeString(`en-${values.stateName}`.split(" ").join("-"));
      case "field_municipality":
        return normalizeString(`en-${values.municipalityName}-${values.stateName === "Estado de México" ? "" : "estado-"}${values.stateName}`.split(" ").join("-"));
      case "field_localidad":
        return normalizeString(`en-${values.localityName}-municipio-${values.municipalityName}-${values.stateName === "Estado de México" ? "": "estado-"}${values.stateName}`.split(" ").join("-"));
      default:
        return null;
    }
  }

  static buildListingUrlByFilters(filters) {
    // Llaves en orden para armar la URL
    const keys = [
      'field_property_type_id',
      'field_treatement_type_id',
      'field_currency_iso',
      'field_state',
      'field_municipality',
      'field_localidad',
      'field_exterior',
      'field_general',
      'field_recreacion',
      'field_politicas',
      'field_restrooms',
      'field_restrooms_exact',
      'field_bedrooms',
      'field_bedrooms_exact',
      'field_parkingspaces',
      'field_parkingspaces_exact',
      'field_price_min',
      'field_price_max',
      'page',
      'field_direccion'
    ];
    const arrayPathName = [];

    keys.forEach((key) => {
      const actualFilter = filters[key];

      if (key === "field_property_type_id") {
        // If you do not have a property type, allocate real estate generally
        if (filters[key].values.length === 0) {
          arrayPathName.unshift("inmuebles");
        }
      }

      switch (key) {
        case "page": // Specific case
          arrayPathName.push(`${actualFilter.prefix}${actualFilter.values.label}`.toLowerCase().replace(/ /g, '-'));
          break;
        case "field_bedrooms":
        case "field_bedrooms_exact":
          if (filters[key].values.length > 0) {
            let labelBedrooms = filters[key].values[0].value == 1 ? "recamara" : "recamaras";
            actualFilter.values.forEach(value => { // value = [{value: 0, label: ""}, {...}...]
              arrayPathName.push(normalizeString(`${actualFilter.prefix}${value.value}-${labelBedrooms}`.replace(/ /g, '-')));
            });
          }
          break;
        case "field_restrooms":
        case "field_restrooms_exact":
          if (filters[key].values.length > 0) {
            let labelRestrooms = filters[key].values[0].value == 1 ? "baño" : "baños";
            actualFilter.values.forEach(value => { // value = [{value: 0, label: ""}, {...}...]
              arrayPathName.push(normalizeString(`${actualFilter.prefix}${value.value}-${labelRestrooms}`.replace(/ /g, '-')));
            });
          }
          break;
        case "field_parkingspaces":
        case "field_parkingspaces_exact":
          if (filters[key].values.length > 0) {
            let labelParkingspaces = filters[key].values[0].value == 1 ? "estacionamiento" : "estacionamientos";
            actualFilter.values.forEach(value => { // value = [{value: 0, label: ""}, {...}...]
              arrayPathName.push(normalizeString(`${actualFilter.prefix}${value.value}-${labelParkingspaces}`.replace(/ /g, '-')));
            });
          }
          break;
        case "field_localidad":
        case "field_municipality":
        case "field_state":
          actualFilter.values.forEach(value => { // value = {value: 0, label: "", alias: ""}
            arrayPathName.push(normalizeString(`${value.alias}`));
          });
          break;
        default:
          actualFilter.values.forEach(value => { // value = [{value: 0, label: ""}, {...}...]
            let label = '' + value.label;
            label = label.replace(/-/g, '-');
            arrayPathName.push(normalizeString(`${actualFilter.prefix}${label}`.replace(/ /g, '-')));
          });
          break;
      }
    });

    return arrayPathName.join('-');

  }

  getQueryObject() {
    return this.params.getQueryObject();
  }

}
