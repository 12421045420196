// @ts-check
import { useState, useEffect } from 'react'

export function useLocation () {
  const [home, setIsHome] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/es/home' || window.location.pathname === '/home') {
      setIsHome(true)
    } else {
      setIsHome(false)
    }
  }, []);

  return home;
}
