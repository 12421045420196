// This object has an order, when searching by query performs the search starting with the first key
// and ending with the last key.
const initialValueFilters = {
  field_localidad: {name: "field_localidad", values: [], conjunction: "OR", operator: "=", prefix: "en-localidad-"},
  field_municipality: {name: "field_municipality", values: [], conjunction: "OR", operator: "=", prefix: "en-municipio-"},
  field_state: {name: "field_state", values: [], conjunction: "OR", operator: "=", prefix: "en-estado-"},
  field_exterior: {name: "field_exterior", values: [], conjunction: "AND", operator: "=", prefix: "con-"},
  field_general: {name: "field_general", values: [], conjunction: "AND", operator: "=", prefix: "con-"},
  field_recreacion: {name: "field_recreacion", values: [], conjunction: "AND", operator: "=", prefix: "con-"},
  field_politicas: {name: "field_politicas", values: [], conjunction: "AND", operator: "=", prefix: "con-"},
  field_property_type_id: {name: "field_property_type_id", values: [], conjunction: "AND", operator: "=", prefix: ""},
  field_treatement_type_id: {name: "field_treatement_type_id", values: [], conjunction: "AND", operator: "=", prefix: "en-"},
  field_currency_iso: {name: "field_currency_iso", values: [], conjunction: "OR", operator: "=", prefix: ""},
  field_restrooms: {name: "field_restrooms", values: [], conjunction: "OR", operator: ">=", prefix: "mas-de-"},
  field_restrooms_exact: {name: "field_restrooms", values: [], conjunction: "OR", operator: "=", prefix: "solo-"},
  field_bedrooms: {name: "field_bedrooms", values: [], conjunction: "OR", operator: ">=", prefix: "mas-de-"},
  field_bedrooms_exact: {name: "field_bedrooms", values: [], conjunction: "OR", operator: "=", prefix: "solo-"},
  field_parkingspaces: {name: "field_parkingspaces", values: [], conjunction: "OR", operator: ">=", prefix: "mas-de-"},
  field_parkingspaces_exact: {name: "field_parkingspaces", values: [], conjunction: "OR", operator: "=", prefix: "solo-"},
  field_price_min: {name: "field_price", values: [], conjunction: "OR", operator: ">=", prefix: "desde-"},
  field_price_max: {name: "field_price", values: [], conjunction: "OR", operator: "<=", prefix: "hasta-"},
  page: {name: "page", values: { value: 1, label: 1 }, conjunction: "OR", operator: "=", prefix: "pagina-"},
  field_direccion: {name: "field_direccion", values: [], conjunction: "OR", operator: "=", prefix: "q-"},
}


export default class Filters {

  // These filters have an order, do not alter the order.
  static filters = initialValueFilters;

  static addFilter(filterKey, value) {
    if(Array.isArray(Filters.filters[filterKey].values)) {
      Filters.filters[filterKey].values.push(value);
    } else {
      Filters.filters[filterKey].values = value;
    }
  }

  static setFilter(key, value) {
    Filters.filters[key] = {...Filters.filters[key], values: value}
  }

  static resetFilters() {
    const keys = Object.keys(Filters.filters);
    keys.forEach(filterKey => {
      if(filterKey === "page") {
        Filters.filters[filterKey].values = { value: 1, label: 1, alias: 'pagina-1' }
        return
      }
      Filters.filters[filterKey].values = []
    });
  }

}
