export function formatDate(input) {
  const date = new Date(input)
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

export function absoluteUrl(input) {
  return `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${input}`
}

export function absoluteUrlNext(input) {
  return `${process.env.NEXT_PUBLIC_NEXT_URL}${input}`
}

export function getOffsetPage(page, itemsPerPage) {
  return (page - 1) * itemsPerPage;
}

export function getParamsPaginator(numberPage, itemsPerPage, totalItems) {
  const offset = getOffsetPage(numberPage, itemsPerPage);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  return [offset, totalPages];
}


export function getLocalStorage(key) {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem(key));
  }
  return null;
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function updateLocalStorageRecentSearch(key, value) {
  const oldValues = getLocalStorage(key);
  if (oldValues !== null) {
    if (oldValues.length === 5) oldValues.pop();
    const alreadyExist = oldValues.find((oldValue) => oldValue.id === value.id);
    if (!alreadyExist) {
      localStorage.setItem(key, JSON.stringify([...oldValues, value]));
    }
  } else {
    localStorage.setItem(key, JSON.stringify([value]));
  }
}

export function sortFeatures(value) {
  const arrayFeatures = value.map((val) => {
    return {value: val.value, label: val.label}
  });
  return arrayFeatures.sort((a, b) => {
    if (parseInt(a.value) < parseInt(b.value)) return -1;
  });
}


export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + '...';
  }
}

// Se creo un nuevo objeto para sobreestibir el titulo y modificar aquellos titulos H1 que deberian ser mas descriptivos
export const customTitles = [
  {
    page: '/home',
    title: 'Tu Portal para Comprar o Rentar el Hogar Ideal en México'
  }
]
