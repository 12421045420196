
/**
 * Remove the acents, and set lowerCase
 * @param text {string}
 * @returns {*}
 */
export default function normalizeString(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f,]/g, "").toLowerCase();
}

export function normalizeStringForUrl(text) {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f,]/g, "")
    .toLowerCase()
    .replace(/-+/g, " ")
    .replace(/  +/g, " ")
    .split(" ")
    .join("-");
}
