export const TextfieldStyles = () => {
  return {
    minWidth: { xl: "275px" },
    "& .MuiInputBase-root": { borderRadius: "4px" },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#000000" },
    ".MuiInputLabel-root": { color: "#000000", fontWeight: 600 },
    ".MuiSvgIcon-root": { color: "#000000", fontSize: "3rem" },
    "& input::placeholder": { color: "#000000" },
    "& .MuiAutocomplete-tag": {maxWidth: "calc(65% - 6px)"},
    "& .MuiAutocomplete-endAdornment": {
      top: "2px",
      "& .MuiAutocomplete-clearIndicator": {
        "& .MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },
      },
    },
  };
};

export const AutocompleteStyles = () => {
  return {
    "& label.Mui-focused": { color: "#10312B" },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": { borderColor: "#000000" },
    },
    "& .MuiChip-deleteIcon:hover": { color: "#AB2328" },
    "& .MuiChip-label": { color: "#10312B" },
    "& .MuiChip-root": { borderRadius: "4px" },
  };
};
