import { DrupalJsonApiParams } from "drupal-jsonapi-params";

/**
 *
 * @param name {
 * string |
 * "node--article" |
 * "node--inmueble" |
 * "node--landing_page"
 * } - The name of the resource.
 * @param mode {
 * string |
 * null |
 * "teaser" |
 * "view" |
 * "facet"
 * } - The mode of the resource.
 * @return {DrupalJsonApiParams}
 */
export function getParams(
  name,
  mode= null
) {
  const params = new DrupalJsonApiParams();
  name = mode ? `${name}--${mode}` : name;

  switch (name) {
    case "node--article":
      return params
        .addFilter("status", "1")
        .addInclude([
          "field_media_image.field_media_image",
          "field_tags",
          "uid"
        ]);
    case "node--article--teaser":
      return params
        .addFilter("status", "1")
        .addInclude([
          "field_media_image.field_media_image",
          "field_tags",
          "uid"
        ]);
    case "node--article--view":
      return params
        .addFields("node--article", [
          "body",
          "title",
          "field_tags",
          "path",
          "field_media_image"

        ])
        .addInclude([
          "field_tags",
          "field_media_image.field_media_image",
        ]);
    case "node--inmueble":
      return params
        .addFilter("status", "1")
        .addFields("node--inmueble", [
          "path",
          "title",
          "body",
          "field_advertisement_id",
          "field_agent_email",
          "field_agent_facebook",
          "field_agent_facebook",
          "field_agent_linkendin",
          "field_agent_name",
          "field_agent_phone",
          "field_agent_twitter",
          "field_agent_whatsapp",
          "field_area_construction",
          "field_area_terrain",
          "field_bedrooms",
          "field_colonie",
          "field_co_ownership",
          "field_currency_iso",
          "field_direccion",
          "field_id",
          "field_investor",
          "field_in_auction",
          "field_legal_impediment",
          "field_link_imagen",
          "field_listing_id",
          "field_livingroom_number",
          "field_maintenance",
          "field_not_accepting_credits",
          "field_ocultar_precio",
          "field_ocultar_ubicacion",
          "field_parkingspaces",
          "field_postal_code",
          "field_price",
          "field_price_eur",
          "field_price_eur_rent",
          "field_price_eur_sell",
          "field_price_rent",
          "field_price_rent_m2",
          "field_price_sell",
          "field_price_sell_m2",
          "field_price_usd",
          "field_price_usd_rent",
          "field_price_usd_sell",
          "field_property_id",
          "field_restrooms",
          "field_seo_title",
          "field_source_id",
          "field_street",
          "field_toilet_number",
          "field_ubicacion",
          "field_unit_construction",
          "field_unit_terrain",
          "field_virtual_tour",
          "field_year_construction",
          "field_agencia",
          "field_agent_id",
          "field_contacto", // node--identidad
          "field_exterior", // taxonomy_term--exterior
          "field_general", // taxonomy_term--general
          "field_imagenes",
          "field_localidad", // taxonomy_term--localidades
          "field_municipality", // taxonomy_term--municipality
          "field_politicas", // taxonomy_term--politicas
          "field_property_type_id", // taxonomy_term--property_type
          "field_recreacion", // taxonomy_term--recreacion
          "field_state", // taxnonomy_term--state
          "field_treatement_type_id", //taxonomy_term--treatment_type_id
        ])
        .addFields("node--identidad", [
          "title",
          "field_email",
          "field_facebook",
          "field_instagram",
          "field_sitio_web",
          "field_telefono_oficina",
          "field_twitter",
          "field_youtube",
          "field_imagen", // media--image
        ])
        .addFields("taxonomy_term--exterior", [
          "name",
          "description",
          "metatag"
        ])
        .addFields("taxonomy_term--general", [
          "name",
          "description",
          "metatag"
        ])
        .addFields("taxonomy_term--localidades", [
          "name",
          "description",
          "metatag",
        ])
        .addFields("taxonomy_term--municipality", [
          "name",
          "description",
          "metatag",
        ])
        .addFields("taxonomy_term--politicas", [
          "name",
          "description",
          "metatag",
        ])
        .addFields("taxonomy_term--property_type", [
          "name",
          "description",
          "metatag",
        ])
        .addFields("taxonomy_term--recreacion", [
          "name",
          "description",
          "metatag",
        ])
        .addFields("taxonomy_term--state", [
          "name",
          "description",
          "metatag",
        ])
        .addFields("taxonomy_term--treatment_type_id", [
          "name",
          "description",
          "metatag",
        ])
        .addFields("media--image", [
          "name",
          "metatag",
          "thumbnail",
          "field_media_image",
        ])
        .addInclude([
          "field_imagenes.field_media_image",
          "field_contacto.field_imagen.field_media_image",
          "field_property_type_id",
          "field_treatement_type_id",
          "field_agencia",
          "field_agencia.field_agency_logo",
          "field_contacto",
          "field_agent_id",
          "field_municipality",
          "field_localidad",
          "field_state",
          "field_general",
          "field_exterior",
          "field_recreacion",
          "field_politicas"
        ]);
    case "node--inmueble--facet":
      return params.addFields("node--inmueble", [
        "title",
        "path",
        "field_imagenes",
        "field_link_imagen",
        "field_property_type_id",
        "field_toilet_number",
        "field_bedrooms",
        "field_parkingspaces",
        "field_price",
        "field_currency_iso",
        "field_state",
        "field_street",
        "field_ubicacion",
        "field_restrooms",
        "field_treatement_type_id",
        "field_area_terrain",
        "field_area_construction",
        "field_unit_construction",
        "field_unit_terrain",
      ])
        .addInclude([
          "field_imagenes.field_media_image",
          "field_property_type_id",
          "field_state",
          "field_treatement_type_id"
        ])
        .addFields("taxonomy_term--property_type", [
          "name"
        ])
        .addFields("taxonomy_term--state", [
          "name"
        ])
        .addFields("taxonomy_term--treatment_type_id", [
          "name"
        ]);
    case "node--inmueble--view":
      return params.addFields("node--inmueble", [
        "title",
        "path",
        "field_imagenes",
        "field_link_imagen",
        "field_property_type_id",
        "field_toilet_number",
        "field_bedrooms",
        "field_parkingspaces",
        "field_price",
        "field_currency_iso",
        "field_restrooms",
        "field_treatement_type_id",
        "field_area_terrain",
        "field_area_construction",
        "field_unit_construction",
        "field_unit_terrain"
      ])
        .addFields("taxonomy_term--property_type", [
          "name"
        ])
        .addFields("taxonomy_term--treatment_type_id", [
          "name"
        ])
        .addInclude([
          "field_imagenes.field_media_image",
          "field_property_type_id",
          "field_treatement_type_id"
        ]);
    case "node--inmueble--teaser":
      return params
        .addFilter("status", "1")
        .addFields("node--inmueble", [
          "body",
          "title",
          "path",
          "uid",
          "created",
          "field_imagenes",
          "field_link_imagen",
          "field_property_type_id",
          "field_toilet_number",
          "field_bedrooms",
          "field_parkingspaces",
          "field_price",
          "field_currency_iso",
          "field_municipality",
          "field_state",
          "field_street",
          "field_treatement_type_id",
        ])
        .addInclude([
          "field_imagenes.field_media_image",
          "uid",
          "field_property_type_id",
          "field_treatement_type_id",
          "field_agencia",
          "field_agent_id",
          "field_municipality",
          "field_state"
        ])
        .addSort("created", "DESC");
    case "node--landing_page":
      return params
        .addFields("node--landing_page", [
          "title",
          "path",
          "field_elementos",
          "field_meta_title",
          "field_meta_keywords",
          "field_meta_description"
        ])
        .addFields("paragraph--banner", [
          "field_boton",
          "field_color_fondo_boton",
          "field_imagen_banner",
          "field_texto",
        ])
        .addFields("paragraph--slider", [
          "field_elementos",
          "field_tipo_slider"
        ])
        .addFields("paragraph--barra", [
          "field_boton",
          "field_color_fondo_boton",
          "field_texto"
        ])
        .addFields("paragraph--imagen_texto_posicion", [
          "field_boton",
          "field_color_fondo_boton",
          "field_imagen",
          "field_posicion_imagen",
          "field_texto",
          "field_titulo"
        ])
        .addFields("paragraph--esquema", [
          "field_elige_particiones",
          "field_tipo",
          "field_total_comision"
        ])
        .addFields("paragraph--imagen_fondo_texto", [
          "field_boton",
          "field_color_fondo_boton",
          "field_imagen",
          "field_posicion_texto",
          "field_texto"
        ])
        .addFields("paragraph--particion", [
          "field_asesor",
          "field_mensaje",
          "field_porcentaje",
          "field_percent_money"
        ])
        .addFields("paragraph--slider_item", [
          "field_imagen",
          "field_link_slider",
          "field_subtitulo",
          "field_title"
        ])
        .addFields("media--image", [
          "field_media_image",
          "metatag",
          "thumbnail"
        ])
        // .addFields("file--file", [
        //   "links"
        // ])
        // .addFields("paragraph--slider", [
        //   "id",
        //   "type"
        // ])
        .addInclude([
          "field_elementos.paragraph_type",
          "field_elementos.field_imagen.field_media_image",
          "field_elementos.field_imagen_banner.field_media_image",
          "field_elementos.field_elementos",
          "field_elementos.field_elementos.field_imagen.field_media_image",
        ]);
    default:
      return params;
  }
}
