import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";
import {InputAdornment} from "@mui/material";
import dynamic from 'next/dynamic';
import {TextfieldStyles as StylesTextfield, AutocompleteStyles as StylesAutocomplete} from './UniversalSearch.styles'
import FiltersBuilder from "/lib/FiltersBuilder";

const DCustomIcon = dynamic(() => import('../../atoms/CustomIcon'),{ssr: false});

export default function UniversalSearchBar({ selectedStates, selectedMunicipality, selectedLocalidades, callback, home }) {
  const selectedStatesFormatted = selectedStates.map((state) => {
    const originalLabel = state.label.includes(',') ? state.label.split(',')[0] : state.label;
    return { name: "field_state", group: "Estado",
      value: state.value,
      label: state.label,
      originalLabel
    }
  });
  const selectedMunicipalityFormatted = selectedMunicipality.map((municipality) => {
    const originalLabel = municipality.label.toString();
    return { name: "field_municipality", group: "Municipio",
      value: municipality.value,
      label: municipality.label,
      originalLabel: originalLabel.split(',')[0]
    }
  });
  const selectedLocalidadesFormatted = selectedLocalidades.map((localidad) => {
    const originalLabel = localidad.label.toString();
    return { name: "field_localidad", group: "Localidad",
      value: localidad.value,
      label: localidad.label,
      originalLabel: originalLabel.split(',')[0]
    }
  });

  const defaultValues = [
    {
      "name": "field_state",
      "group": "Estado",
      "value": 23,
      "originalLabel": "Ciudad de México",
      "label": "Ciudad de México"
    },
    {
      "name": "field_state",
      "group": "Estado",
      "value": 29,
      "originalLabel": "Estado de México",
      "label": "Estado de México"
    },
    {
      "name": "field_state",
      "group": "Estado",
      "value": 36,
      "originalLabel": "Querétaro",
      "label": "Querétaro"
    },
    {
      "name": "field_state",
      "group": "Estado",
      "value": 19,
      "originalLabel": "Coahuila",
      "label": "Coahuila"
    },
    {
      "name": "field_state",
      "group": "Estado",
      "value": 33,
      "originalLabel": "Nuevo León",
      "label": "Nuevo León"
    }
  ]

  const [value, setValue] = useState([...selectedStatesFormatted, ...selectedMunicipalityFormatted, ...selectedLocalidadesFormatted]);
  const [options, setOptions] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const datos =
      typeof window !== "undefined"
        ? JSON.parse(localStorage.getItem("lastSearch"))
        : [];
    const datosActualizados = datos?.map((dato) => {
      return { ...dato, group: "Ultimas Busquedas" };
    });

    setOptions(
      datosActualizados
        ? datosActualizados.slice(-3).concat(defaultValues)
        : defaultValues
    );

    const firstUpdatedOption = options[0];
    setValue(firstUpdatedOption);
  }, []);

  // Update every the value when, municipality, township changes.
  useEffect(() => {
    setValue([...selectedStatesFormatted, ...selectedMunicipalityFormatted, ...selectedLocalidadesFormatted])
  }, [selectedStates, selectedMunicipality, selectedLocalidades]);

  const onChange = (event, valuesUpdated, reason, option) => {
    option = option?.option;
    switch (reason) {
      case "createOption":
        setValue((oldValues) => [...oldValues, { name: "created", group: "", value: option, label: option}]);
        break;
      case "removeOption":
        setValue(valuesUpdated);
        const newValues = valuesUpdated.map(value => {
          return { value: value.value, label: value.label, alias: FiltersBuilder.buildAlias(value.name, {
            stateName: value.originalLabel
          }) }
        });
        callback(option.name, newValues);
        break;
      case "clear":
        setValue([]);
        callback("field_state", []);
        callback("field_municipality", []);
        callback("field_localidad", []);
        break;
      case "selectOption":
      default:
        setValue(valuesUpdated);
        localStorage.setItem('lastSearch', JSON.stringify(valuesUpdated));
        if(option.name === "field_state") {
          callback(
            option.name, {
              value: option.value,
              label: option.label,
              alias: FiltersBuilder.buildAlias(option.name, {
                stateName: option.originalLabel
              })
            });
          break;
        } else if(option.name === "field_municipality") {
          callback(
            option.name, {
              value: option.value,
              label: option.label,
              alias: FiltersBuilder.buildAlias(option.name, {
                stateName: option.estado.label,
                municipalityName: option.originalLabel,
              })
            });
          break;
        } else if(option.name === "field_localidad") {
          callback(
            option.name, {
              value: option.value,
              label: option.label,
              alias: FiltersBuilder.buildAlias(option.name, {
                stateName: option.estado.label,
                municipalityName: option.municipio.label,
                localityName: option.originalLabel,
              })
            });
          break;
        }
    }
  }

  // Aqui poner la lógica para que se espere algunos milisegundos y haga la busqueda
  const onInputChange = (event, value, reason) => {
    if (value === "") {
      setOptions(defaultValues);
      setLoading(false);
      return;
    };

    setLoading(true);
    clearTimeout(newTimer);
    const newTimer = setTimeout(async () => {
      const request = await fetch(`/api/getAutocompleteOptions?q=${value}`);
      const data = await request.json();
      if(!request.ok) return console.error("Error al obtener los tokens de la barra de búsqueda.", request);
      setOptions(request.ok ? data : []);
      setLoading(false);
    }, 200);
    setTimer(newTimer);
  }


  return (
    <Autocomplete
      multiple
      autoComplete={true}
      autoHighlight={true}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => {
        return (
          option.name === value.name &&
          option.value === value.value
        )}}
      limitTags={1}
      // freeSolo // TODO
      loading={loading}
      loadingText="Cargando..."
      id="direction-tags"
      options={options}
      value={value}
      onChange={onChange}
      noOptionsText={"Sin opciones"}
      onInputChange={onInputChange}
      renderInput={(params) => {
        let startAdornment = params.InputProps.startAdornment
          ? params.InputProps?.startAdornment
          : [];
        return (
          <TextField
            {...params}
            label="Ubicación"
            multiline={true}
            rows={1}
            InputProps={{
              ...params.InputProps,
              startAdornment: [
                <InputAdornment position="start" key={"start"}>
                  <DCustomIcon iconName="search" viewBox="0 0 40 50" sx={{ fontSize: "1.5rem !important" }}/>
                </InputAdornment>,
                ...startAdornment,
              ]
            }}
            sx={StylesTextfield}
          />
        )
      }}
      sx={StylesAutocomplete}
    />
  )
}
