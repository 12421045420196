import PropTypes from "prop-types";
import { Button } from "@mui/material";

function ButtonPrimary({
  url,
  colorTextButton,
  backgroundButton,
  textButton,
  onClick,
  type,
  sx,
  startIcon,
  endIcon,
  name,
  ...otherProperties
}) {

  return (
    <>
      <Button
        sx={{ sx,
          fontSize: { sm: "12px", md: "14px" },
          width: "100%",
          height: "100%",
          fontWeight: 700,
        '&.MuiButton-root': {
          backgroundColor: backgroundButton,
          color: colorTextButton
        }}}
        startIcon={startIcon}
        endIcon={endIcon}
        target={"_blank"}
        onClick={onClick}
        type={type}
        href={url}
        variant="contained"
        name={name}
        {...otherProperties}
      >
        {textButton}
      </Button>
    </>
  );
}

ButtonPrimary.propTypes = {
  url: PropTypes.string,
  colorTextButton: PropTypes.string,
  backgroundButton: PropTypes.string,
  textButton: PropTypes.string,
};

export default ButtonPrimary;
